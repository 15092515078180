<script>
import PollNew from './PollNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'PollEditView',
  extends: PollNew,
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('poll/update', this.preparePollRequest(this.poll))
        .then(() => {
          if (this.$store.getters['poll/error'] === null) {
            this.getPoll()
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['poll/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getPoll()
  }
}
</script>
